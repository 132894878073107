import { ElementRef, Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export interface MenuWidth {
	expanded: number
	collapsed: number
}

export const localStorageMenuStyleKey = 'selected-menu-style'
export type LocalStorageMenuStyleValue = 'default' | 'new'

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	menuReference?: ElementRef
	private menuCollapseState$: BehaviorSubject<boolean> = new BehaviorSubject(false)
	private menuWidth: MenuWidth = {
		expanded: 250,
		collapsed: 64,
	}

	constructor() {}

	/**
	 * Returns the collapse state value of the menu.
	 *
	 * @returns {boolean} The collapse state value of the menu.
	 */
	collapseValue(): boolean {
		return this.menuCollapseState$.value
	}

	/**
	 * Toggles the collapse state of the menu.
	 *
	 * @returns {void}
	 */
	toggleCollapse(): void {
		this.menuCollapseState$.next(!this.menuCollapseState$.value)
		this.setMenuCssVariable()
	}

	/**
	 * Set the default width for menu.
	 *
	 * @param {Object} width - The width configuration for menu.
	 * @param {number} width.expanded - The default expanded width for menu.
	 * @param {number} width.collapsed - The default collapsed width for menu.
	 *
	 * @throws {Error} If invalid values are provided.
	 */
	setMenuDefaultWidth(width: { expanded: number; collapsed: number }): void {
		if (!width.expanded || !width.collapsed) {
			throw new Error('Invalid values')
		}
		this.menuWidth = width
	}

	/**
	 * Sets the width of the menu element.
	 *
	 * @method setMenuCssVariable
	 * @returns {void}
	 */
	setMenuCssVariable(): void {
		document.documentElement.style.setProperty(
			'--menu-width',
			`${this.collapseValue() ? this.menuWidth.collapsed : this.menuWidth.expanded}px`,
		)
	}

	/**
	 * Sets the width of the menu.
	 *
	 * @param {MenuWidth} width - The width of the menu.
	 * @return {void}
	 */
	setMenuWidth(width: MenuWidth): void {
		this.menuWidth = width
		this.setMenuCssVariable()
	}

	setLocalStorageMenuStyle(type: LocalStorageMenuStyleValue): void {
		localStorage.setItem(localStorageMenuStyleKey, type)
	}

	get getLocalStorageMenuStyle(): LocalStorageMenuStyleValue {
		return (localStorage.getItem(localStorageMenuStyleKey) as LocalStorageMenuStyleValue) ?? 'default'
	}
}
